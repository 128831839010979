import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Button, UncontrolledTooltip } from "reactstrap";
import { PAGE } from "utility/helper/constants";
import { history } from "utility/helper/history";
import { splitData } from "utility/transformers";

const PrimaryButton = observer(
  ({
    onClick,
    isDisabled,
    icon,
    outline,
    text,
    customClasses,
    customIconClasses,
    customTextClasses,
    toolTip: ttProp,
    toolTipText: tttProp,
    type,
    styles = null,
    RatingDate,
    typeStatus,

  }) => {
    console.log("Text", text, "cutom classes", customClasses, "customTextClasses", customTextClasses);
   


    const tooltip = tttProp || splitData(ttProp, "_", " ");

    const shouldRenderButton = text !== "Rate Now" || RatingDate == null && typeStatus!=="preventive";
  
    return (
      <Fragment>
        {shouldRenderButton && (
          <>
            <Button
              type={type || "button"}
              color="primary"
              style={styles}
              className={`custom-primary-btn click-able ${customClasses}`}
              onClick={(e) => {
                if (onClick) onClick(e);
                // Added this localStorage event to handle the previous and current URL location
                const currentPath = localStorage.getItem(PAGE.CURRENT);
                localStorage.setItem(PAGE.PREVIOUS, currentPath);
                localStorage.setItem(PAGE.CURRENT, history?.location?.pathname);
              }}
              disabled={isDisabled}
              id={ttProp}
              outline={outline}
            >
              {icon && <figure className={`custom-primary-btn--icon ${customIconClasses}`}>{icon}</figure>}
              {text && (
                <div className={`custom-primary-btn--text ${customTextClasses} ${!icon ? "ml-0" : ""}`}>
                  <FormattedMessage id={text} defaultMessage={text} />
                </div>
              )}
            </Button>

            {ttProp && (
              <UncontrolledTooltip placement="bottom" target={ttProp}>
                <FormattedMessage id={tooltip} defaultMessage={tooltip} />
              </UncontrolledTooltip>
            )}
          </>
        )}
      </Fragment>
    );
  }
);

export default PrimaryButton;
