const initialState = {
  isInstallationForm: false,
  isLocationForm:false,
};
export const assetAcceptance = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_INS_FORM": {
      return {
        ...state,
        isInstallationForm: action.payload,
      };
    }
    case "TOGGLE_LOCATION_FORM": {
      return {
        ...state,
        isLocationForm: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
